<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-3">
					<div class="text-center">
						<h4 class="mb-3">{{ title }}</h4>
						<p class="mb-0">{{ desc1 }}<br> {{ desc2 }}<br>{{ desc3 }}</p>
						<!-- <p v-b-modal.modal-3 variant="link" class="mb-0"><u>수집이 잘 되지 않는 경우 조치방법</u></p>
							<b-modal id="modal-3" size="sm" title="수집이 잘 되지 않는 경우 조치방법" ok-only>
								<p>1. 확장 설치 후 쿠플러스 사이트를 닫았다가 열어서 다시 로그인 후에 키워드 분석 시도하기</p>
								<p>2. 크롬 브라우저 사용하기</p>
								<p>3. 확장앱 중 1688/타오바오/알리바바 이미지서치 앱 삭제</p>
								<p>4. 크롬 브라우저의 계정을 별도의 계정으로 추가해서 쿠플러스만 확장 설치 후 실행하기</p>
                            </b-modal> -->
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="d-flex justify-content-center h-100">
					<input ref="cursor" type="text" class="form-control w-25 h-100 text-center rounded-pill"
						placeholder="키워드 추가" v-model="targetKeyword" @keyup.enter="addKeyword($event)"
						@change="keyinkeywords">
				</div>
			</div>
			<div class="col-lg-12 d-flex justify-content-center align-items-center" v-if="showspinner">
				<div class="custom-control custom-control-inline d-flex justify-content-center">
					<div class="row">
						<div class="col-12">
							<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
							<span>{{ loadingtext }}... {{ loadingrate }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-12 d-flex justify-content-center align-items-center border">
				<b-badge variant="primary text-center">키워드 수집</b-badge>
				<div class="ml-3 pl-2 border-left">
					<button type="button" class="btn btn-outline-primary ml-2" v-b-modal.relKeyword>연관+자동완성</button>
					<button type="button" class="btn btn-outline-primary ml-2" v-b-modal.keywordRank>네이버데이터랩</button>
					<button type="button" class="btn btn-outline-primary ml-2" v-b-modal.excelupload>대량업로드</button>
				</div>
			</div>


			<div class="col-lg-12 d-flex justify-content-center align-items-center border-left border-right border-bottom"
				v-if="this.keywords.length > 0">
				<b-badge variant="success text-center">데이터 수집</b-badge>
				<div class="ml-3 pl-3 border-left" style="font-size:80%">
					<div>
						<div class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
							<input type="checkbox" class="custom-control-input bg-primary" id="customCheck-1"
								v-model="check1" @change="checkShowItems">
							<label class="custom-control-label" for="customCheck-1"> 쿠팡월검색량, 검색트렌드, 쿠팡키워드CPC</label>
						</div>
					</div>
					<div>
						<div class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
							<input type="checkbox" class="custom-control-input bg-primary" id="customCheck-2"
								v-model="check2" @change="checkShowItems">
							<label class="custom-control-label" for="customCheck-2"> 쿠팡중간판매가, 쿠팡판매가범위(25~75%범위),
								쿠팡평균판매가, 상위그로스비율, 상위로켓비율, 상위윙비율, 최적카테고리(적합도), 평균월조회수, 상위예상월매출, 상위예상일판매</label>
						</div>
					</div>
					<div>
						<div class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
							<input type="checkbox" class="custom-control-input bg-primary" id="customCheck-4"
								v-model="check3" @change="checkShowItems">
							<label class="custom-control-label" for="customCheck-4"> 쿠팡1점리뷰비율(반품율), 쿠팡중간매출, 쿠팡평균매출
							</label>
						</div>
					</div>



					<!-- <div class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
						<input type="checkbox" class="custom-control-input bg-primary" id="customCheck-14" v-model="showNaverAvgSales" disabled>
						<label class="custom-control-label" for="customCheck-14"> 네이버평균매출</label>
					</div> -->
				</div>
			</div>
			<div class="col-lg-12 d-flex justify-content-center align-items-center border-left border-right border-bottom"
				v-if="this.keywords.length > 0">
				<button type="button" class="btn btn-outline-primary" @click="search">키워드 데이터 수집시작</button>
				<button type="button" class="btn btn-outline-secondary ml-2" @click="deleteKeywords">삭제</button>
				<button type="button" class="btn btn-outline-success ml-2" @click="downloadKeywords">다운로드</button>
			</div>


			<b-modal :id="'relKeyword'" size="xl" title="쿠팡 연관+자동완성 키워드 수집" ok-title="수집하기" cancel-title="취소"
				@ok="collectRelKeyword">
				<div class="row">
					<div class="col-md-12 d-flex justify-content-center">
						<input ref="cursor" type="text" class="form-control text-center rounded-pill"
							placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="collectRelKeywordEnter($event)">
					</div>
					<div class="col-md-12 d-flex align-items-center justify-content-start">
						<div class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
							<input type="checkbox" class="custom-control-input bg-primary" id="customCheck-18"
								v-model="relKeywordChecked">
							<label class="custom-control-label" for="customCheck-18"> 연관키워드</label>
						</div>
						<div class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
							<input type="checkbox" class="custom-control-input bg-primary" id="customCheck-17"
								v-model="autoCompleteChecked">
							<label class="custom-control-label" for="customCheck-17"> 자동완성키워드</label>
						</div>
						<div class="ml-4">
							반복횟수
						</div>
						<div class="custom-control-inline ml-3 d-flex justify-content-start">
							<input type="number" :min="1" :max="500" class="form-control text-center" placeholder=""
								aria-label="" v-model="relCycle">
						</div>
					</div>
				</div>
			</b-modal>

			<b-modal :id="'keywordRank'" size="xl" title="네이버 데이터랩 키워드 수집" ok-title="수집하기" cancel-title="취소"
				@ok="collectKeyword">
				<div class="row">
					<div class="col-md-12 mt-2 d-flex align-items-center justify-content-between">
						<div style="font-size:85%"><b>[수집할 키워드 순위설정]</b><br>새탭에 네이버 데이터랩 쇼핑인사이트<br>Top500키워드가 나오도록 먼저
							세팅해주세요</div>
						<div class="custom-control-inline ml-3 d-flex justify-content-center">
							<input type="number" :min="1" :max="500" class="form-control" placeholder="" aria-label=""
								v-model="startRank" style="text-align:center;">
							~
							<input type="number" :min="1" :max="500" class="form-control" placeholder="" aria-label=""
								v-model="endRank" style="text-align:center;">
						</div>
					</div>
				</div>
			</b-modal>

			<b-modal size="xl" id="excelupload" scrollable title="키워드대량업로드" ok-only>
				<div class="row">
					<div class="col-md-12">
						<!-- <p><b style="color:red">1688을 통해 구매하지 않거나, 별도 재고로 관리하는 사입제품의 경우, 구매링크를 '사입'이라고 표시해 주세요</b></p>
                        <p>신규상품, 발주상태 등 상품 내역에 변경사항이 있을 경우 서플라이어에 접속한 후 <u v-b-modal.modal-3>크롬확장프로그램</u>으로 SKU 리스트를 먼저 수집해 주세요</p> -->
						<p>[업로드 방법]</p>
						<p>1. 엑셀업로드 포맷 다운로드<br>
							2. 엑셀 파일에서 키워드 부분(맨윗줄 제외) 업데이트<br>
							3. 업데이트된 엑셀 파일 업로드 순으로 진행해 주세요.</p>

					</div>
					<div class="col-md-12 mt-4">
						<div class="form-group">
							<label>1. 엑셀업로드 포맷</label>
							<div>
								<button type="reset" @click="formatDownload"
									class="btn btn-primary mr-2">Download</button>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label for="Code">2. 업데이트된 구매요청양식 업로드</label>
							<div class="custom-file">
								<input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input"
									id="inputGroupFile02">
								<label class="custom-file-label" for="inputGroupFile02">{{ uploadstatus }}</label>
							</div>
							<div class="col-md-12" v-if="showuploadspinner">
								<div>
									<img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-modal>



			<div class="col-lg-12 mt-4" v-if="showlist">
				<CDataTable :headers="headers" :items="keywords" v-model="tableSelectedItem" :show-select="true"
					itemKey="keyword" style="margin-bottom: 10px">
					<template v-slot:add="{ item }">
						<div v-if="!item.add">
							<b-button variant="primary" @click="addRelKeyword(item)">추가</b-button>
						</div>
					</template>
					<template v-slot:del="{ item }">
						<button type="button" class="btn btn-outline-secondary ml-2"
							@click="deleteKeyword(item)">x</button>
					</template>
					<template v-for="header in slotHeaders" v-bind:slot="header.value" slot-scope="{ item }">
						{{ typeof item[header.value] === 'number' ? item[header.value]?.toLocaleString() :
							item[header.value] }}
					</template>
				</CDataTable>
			</div>

		</div>
	</div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
import IndexedDBStore from '@/components/common/IndexedDB';

// import { chrome } from 'vue-chrome-extension';
export default {
	name: 'rank',
	data() {
		return {
			headers: [
				{ text: '키워드', value: 'keyword', align: 'center', width: 200, isSlot: false },
				// {text: '구매관리', value: '구매정보', align: 'center', width: 100, isSlot: true},
				// {text: '판매링크', value: '판매링크', align: 'center', width: 100, isSlot: true},
				// {text: '아이템위너', value: '아이템위너', align: 'center', width: 100, isSlot: true},
				// {text: '뱃지', value: '뱃지', align: 'center', width: 100, isSlot: true},
				// {text: '사이즈', value: '사이즈', align: 'center', width: 100, isSlot: true},
				// {text: '판매가', value: '판매가', align: 'center', width: 100, isSlot: true},
				// {text: '할인쿠폰', value: '할인쿠폰', align: 'center', width: 100, isSlot: true},
				// {text: '수수료', value: '수수료', align: 'center', width: 100, isSlot: true},
				// {text: '매입가', value: '매입가', align: 'center', width: 100, isSlot: true},
				// {text: '마진', value: '마진', align: 'center', width: 100, isSlot: true},
				// {text: '마진율\nROI\nEnd ROAS', value: '마진율', align: 'center', width: 100, isSlot: true},
				{ text: '연관+자동완성', value: 'add', align: 'center', width: 100, isSlot: true },
				{ text: '삭제', value: 'del', align: 'center', width: 100, isSlot: true },
			],
			// headers: [
			//     {text: '키워드', value: 'keyword', align: 'center', width: 500, isSlot: true},
			//     {text: '구매관리', value: '구매정보', align: 'center', width: 100, isSlot: true},
			//     {text: '판매링크', value: '판매링크', align: 'center', width: 100, isSlot: true},
			//     {text: '아이템위너', value: '아이템위너', align: 'center', width: 100, isSlot: true},
			//     {text: '뱃지', value: '뱃지', align: 'center', width: 100, isSlot: true},
			//     {text: '사이즈', value: '사이즈', align: 'center', width: 100, isSlot: true},
			//     {text: '판매가', value: '판매가', align: 'center', width: 100, isSlot: true},
			//     {text: '할인쿠폰', value: '할인쿠폰', align: 'center', width: 100, isSlot: true},
			//     {text: '수수료', value: '수수료', align: 'center', width: 100, isSlot: true},
			//     {text: '매입가', value: '매입가', align: 'center', width: 100, isSlot: true},
			//     {text: '마진', value: '마진', align: 'center', width: 100, isSlot: true},
			//     {text: '마진율\nROI\nEnd ROAS', value: '마진율', align: 'center', width: 100, isSlot: true},
			//     // {text: 'Actions', value: '', align: 'center', width: 100, isSlot: true},
			// ],
			tableSelectedItem: [],
			keywords: [],
			db: null,

			showuploadspinner: false,
			uploadstatus: 'excel file upload',

			check1: true,
			check2: false,
			check3: false,

			showCoupangMonthlySearch: true,
			showCoupangMedianPrice: false,
			showCoupangPriceRange: false,
			showCoupangOneStarRate: false,
			showOptimalCategory: false,
			showCoupangAvgPrice: false,
			showCoupangMedianSales: false,
			showCoupangAvgSales: false,
			// showCoupangMedianViews: false,
			showCoupangAvgViews: false,
			showCoupangRecCPC: false,
			showCoupangTopGrowthRate: false,
			showCoupangTopRocketRate: false,
			showCoupangTopWingRate: false,
			// showNaverAvgSales: false,
			showSearchTrend: false,

			relKeywordChecked: true,
			autoCompleteChecked: true,

			relCycle: 1,
			// showDataCollect:false,
			related: false,
			auth: '',
			loadingtext: '연관검색어 수집 중',
			loadingrate: '',

			showspinner: false,
			showlist: false,

			waitresult: false,
			targetKeyword: '',
			fullsearch: false,
			addlines: 10,
			rowlines: [],

			title: "키워드 수집",
			desc1: "키워드 수집방법과 데이터 수집방법을 선택한 후 수집합니다.",
			desc2: "쿠팡wing, 광고센터를 새탭에서 열고 수집해주세요",
			desc3: "",
			startRank: 1,
			endRank: 500,
		}
	},
	components: {
		CDataTable,
	},
	// watch: {
	// keywords: {
	// 	handler(newKeywords) {
	// 		// IndexedDB에 저장
	// 		// if (this.db) {
	// 		// 	this.db.saveKeywords(newKeywords).catch(error => {
	// 		// 		console.error('키워드 저장 실패:', error);
	// 		// 	});
	// 		// }
	// 	},
	// 	deep: true
	// }
	// },
	async created() {
		// IndexedDB 초기화
		this.db = new IndexedDBStore();
		await this.db.initDB();

		// 저장된 키워드 불러오기
		try {
			const savedKeywords = await this.db.getKeywords();
			if (savedKeywords && savedKeywords.length > 0) {
				// 1) 'keyword' 필드가 제대로 있는 항목만 필터링
				const validKeywords = savedKeywords.filter(item => {
					// item이 null이 아닌지, item.keyword가 존재하는지
					return item && typeof item.keyword === 'string' && item.keyword.trim().length > 0;
				});

				// 2) 필터링 결과가 전체 개수보다 적으면, 즉 "문제 키워드"가 있었다면 DB에도 재저장
				if (validKeywords.length < savedKeywords.length) {
					await this.db.saveKeywords(validKeywords).catch(error => {
						console.error('DB 저장 중 오류:', error);
					});
					console.log('[created] 문제가 있는 키워드들을 제외하고 DB 재저장 완료');
				}

				// 3) 정상 항목만 this.keywords로 세팅
				this.keywords = validKeywords;
				this.updateHeaders(); // headers 업데이트
				this.showlist = true;
			}
		} catch (error) {
			console.error('키워드 불러오기 실패:', error);
		}
	},

	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
	mounted() {
		this.messageEventHandler = async (event) => {
			if (event.data.res && event.data.res === 'login') {
				alert('쿠팡wing에 로그인 해주세요')
				this.showspinner = false;
				// this.showlist = false;
				return
			}
			if (event.data.res && event.data.res === 'alert') {
				alert(event.data.alert)
				return
			}
			if (event.data.res && event.data.res === 'collectKeyword') {
				if (event.data.keywords && event.data.keywords.length > 0) {
					// this.showlist=false;
					var allKeywords = event.data.keywords;
					for (var keyword of allKeywords) {
						if (keyword.replaceAll(" ", "").length > 0) {
							if (!this.keywords.find(e => e.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", ""))) {
								this.keywords.push({
									keyword: keyword.replaceAll(" ", ""),
									add: false,
									del: false,
								})
							}
						}
					}
				}
				// 키워드를 전부 push한 뒤, DB 저장
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});

				this.$nextTick(() => {
					this.showlist = true;
					this.showspinner = false;
				});
				return
			}
			if (event.data.res && event.data.res === 'datalabKeywordStatus') {
				console.log(event.data.status);
				this.loadingtext = event.data.text;
				this.loadingrate = '(' + (event.data.status * 100).toFixed(0) + "%)";
				this.showspinner = true;
			}
			if (event.data.res && event.data.res === 'keywordanalysisstatus') {
				console.log(event.data.status);
				this.loadingtext = '키워드 별 페이지 분석 중';
				this.loadingrate = '(' + (event.data.status * 100).toFixed(0) + "%)";
				this.showspinner = true;
			}
			if (event.data.res && event.data.res === 'keywordanalysisstatusratio') {
				console.log(event.data.status);
				this.showspinner = true;
				this.loadingtext = event.data.text;
				this.loadingrate = '(' + (event.data.status * 100).toFixed(0) + "%)";
				return
			}
			// if(event.data.res && event.data.res === 'keywordanalysis'){
			// 	if(!event.data.cpc){
			// 		alert('CPC단가는 쿠팡wing에 로그인 후 정상적으로 출력됩니다.')
			// 	}
			//     console.log(event.data.coupang);
			// 	console.log(event.data.naver);
			// 	console.log(event.data.cpc);

			// 	this.loadingtext = '데이터 계산 중';
			// 	this.loadingrate = "";
			// 	this.showspinner = true;
			// 	this.showlist = false;
			// 	this.makerowlines(event.data.coupang,event.data.naver,event.data.cpc);
			// 	// var obj = event.data.result;
			// 	// this.rowlines[obj.index].rank = obj.rank;
			// 	// this.rowlines[obj.index].adqty = obj.adqty;
			// 	// this.rowlines[obj.index].adplace = obj.adplace;
			// 	// this.rowlines[obj.index].productqty = obj.productqty;
			// 	// this.rowlines[obj.index].rocketqty = obj.rocketqty;
			// 	// this.rowlines[obj.index].totalrank = obj.totalrank;
			// 	// this.waitresult = false;
			// 	// await this.sleep(10);
			// 	// this.showspinner = false;
			// 	// this.showlist = true;
			// }
			if (event.data.res && event.data.res === 'adkeywords') {
				// this.showlist=false;
				allKeywords = event.data.relatedkeywords;
				console.log(allKeywords)
				for (keyword of allKeywords) {
					console.log(keyword, keyword.replaceAll(" ", ""), !this.keywords.find(e => e.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")))
					if (!this.keywords.find(e => e.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", ""))) {
						if (keyword.replaceAll(" ", "").length > 0) {
							this.keywords.push({
								keyword: keyword.replaceAll(" ", ""),
								add: false,
								del: false,
							})
						}
					}
				}
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});
				this.$nextTick(() => {
					this.showlist = true;
					this.showspinner = false;
				})
			}
			if (event.data.res && event.data.res === 'collectKeywordData') {
				this.showlist = false;
				console.log(event.data)
				var keywordVolume = event.data.keywordVolume;
				var keywordCPC = event.data.keywordCPC;
				var keywordTrend = event.data.keywordTrend;
				if (keywordVolume) {
					if (!this.headers.find(f => f.value == '검색트렌드지난달')) {
						this.headers.splice(-1, 0, { text: '검색트렌드\n지난달', value: '검색트렌드지난달', align: 'center', width: 100, isSlot: true });
					}
					for (var e of keywordVolume) {
						const found = this.keywords.find(
							f => f.keyword.replaceAll(" ", "") == e.keyword.replaceAll(" ", "")
						);
						if (found) {
							found.검색트렌드지난달 = e?.searchVolume?.EXACT ? e.searchVolume.EXACT : 0;
						}
					}
				}
				if (keywordTrend) {
					if (!this.headers.find(f => f.value == '검색트렌드이번달')) {
						this.headers.splice(-1, 0, { text: '검색트렌드\n이번달', value: '검색트렌드이번달', align: 'center', width: 100, isSlot: true });
					}
					if (!this.headers.find(f => f.value == '검색트렌드+3개월')) {
						this.headers.splice(-1, 0, { text: '검색트렌드\n+3개월', value: '검색트렌드+3개월', align: 'center', width: 100, isSlot: true });
					}
					if (!this.headers.find(f => f.value == '검색트렌드+6개월')) {
						this.headers.splice(-1, 0, { text: '검색트렌드\n+6개월', value: '검색트렌드+6개월', align: 'center', width: 100, isSlot: true });
					}
					for (e of keywordTrend) {
						var monthlySearch = this.keywords.find(f => f.keyword.replaceAll(" ", "") == e.title.replaceAll(" ", "")).검색트렌드지난달;
						var predictResult = await this.predictFutureSearchVolumes(monthlySearch, e.data)
						console.log(predictResult)
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == e.title.replaceAll(" ", ""))["검색트렌드이번달"] = Math.round(predictResult?.oneMonthLaterVolume) ? Math.round(predictResult.oneMonthLaterVolume) : 0;
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == e.title.replaceAll(" ", ""))["검색트렌드+3개월"] = Math.round(predictResult?.threeMonthsLaterVolume) ? Math.round(predictResult.threeMonthsLaterVolume) : 0;
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == e.title.replaceAll(" ", ""))["검색트렌드+6개월"] = Math.round(predictResult?.sixMonthsLaterVolume) ? Math.round(predictResult.sixMonthsLaterVolume) : 0;
					}
				}
				if (keywordCPC) {
					if (!this.headers.find(f => f.value == '쿠팡키워드CPC')) {
						this.headers.splice(-1, 0, { text: '쿠팡\n키워드CPC', value: '쿠팡키워드CPC', align: 'center', width: 100, isSlot: true });
					}
					for (e of Object.keys(keywordCPC)) {
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == e.replaceAll(" ", "")).쿠팡키워드CPC = keywordCPC[e]?.suggested ? keywordCPC[e].suggested : 0;
					}
				}
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});
				this.$nextTick(() => {
					this.showlist = true;
					this.showspinner = false;
				})
			}
			if (event.data.res && event.data.res === 'topProducts') {
				this.showlist = false;
				console.log(event.data)
				if (this.showCoupangMedianPrice) {
					if (!this.headers.find(f => f.value == '쿠팡중간판매가')) {
						this.headers.splice(-1, 0, { text: '쿠팡\n중간판매가', value: '쿠팡중간판매가', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangPriceRange) {
					if (!this.headers.find(f => f.value == '쿠팡판매가범위')) {
						this.headers.splice(-1, 0, { text: '쿠팡판매가\n범위(25~75%)', value: '쿠팡판매가범위', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangAvgPrice) {
					if (!this.headers.find(f => f.value == '쿠팡평균판매가')) {
						this.headers.splice(-1, 0, { text: '쿠팡\n평균판매가', value: '쿠팡평균판매가', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangTopGrowthRate) {
					if (!this.headers.find(f => f.value == '상위그로스비율')) {
						this.headers.splice(-1, 0, { text: '상위\n그로스비율', value: '상위그로스비율', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangTopRocketRate) {
					if (!this.headers.find(f => f.value == '상위로켓비율')) {
						this.headers.splice(-1, 0, { text: '상위\n로켓비율', value: '상위로켓비율', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangTopWingRate) {
					if (!this.headers.find(f => f.value == '상위윙비율')) {
						this.headers.splice(-1, 0, { text: '상위\n윙비율', value: '상위윙비율', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showOptimalCategory) {
					if (!this.headers.find(f => f.value == '카테고리명')) {
						this.headers.splice(-1, 0, { text: '카테고리명', value: '카테고리명', align: 'center', width: 200, isSlot: true });
						this.headers.splice(-1, 0, { text: '카테고리\n적합도', value: '카테고리적합도', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangAvgViews) {
					if (!this.headers.find(f => f.value == '평균월조회수')) {
						this.headers.splice(-1, 0, { text: '상위\n평균월조회수', value: '평균월조회수', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangAvgViews) {
					if (!this.headers.find(f => f.value == '상위예상월매출')) {
						this.headers.splice(-1, 0, { text: '상위\n예상월매출\n(CVR3%\n중간판매가\n기준)', value: '상위예상월매출', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangAvgViews) {
					if (!this.headers.find(f => f.value == '상위예상일판매')) {
						this.headers.splice(-1, 0, { text: '상위\n예상일판매\n(CVR3%\n기준)', value: '상위예상일판매', align: 'center', width: 100, isSlot: true });
					}
				}

				var topProducts = event.data.topProducts;
				keyword = event.data.keyword;
				var keywordCategory = event.data.keywordCategory;
				console.log(topProducts)
				if (topProducts) {
					if (this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", ""))) {
						var array = topProducts.map(f => f.price).sort((a, b) => { return b - a });
						var index25 = Math.floor(array.length * 0.25);
						var index50 = Math.floor(array.length * 0.50);
						var index75 = Math.floor(array.length * 0.75);
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).쿠팡중간판매가 = array[index50];
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).쿠팡평균판매가 = Math.round(array.reduce((pv, cv) => { return pv + cv }, 0) / array.length);
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).쿠팡판매가범위 = array[index75] + "~" + array[index25];
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).상위그로스비율 = Math.round(topProducts.filter(f => f.type == 'jet').length / topProducts.length * 100) / 100
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).상위로켓비율 = Math.round(topProducts.filter(f => f.type == 'rocket').length / topProducts.length * 100) / 100
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).상위윙비율 = Math.round(topProducts.filter(f => f.type == 'wing').length / topProducts.length * 100) / 100
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).카테고리명 = keywordCategory.category;
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).카테고리적합도 = keywordCategory.rate + "%";
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).평균월조회수 = keywordCategory.click;
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).상위예상월매출 = (Math.round(array[index50] * keywordCategory.click / 28 * 30 * 0.03));
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).상위예상일판매 = Math.round(keywordCategory.click / 28 * 0.03);
					}
				}
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});
				this.$nextTick(() => {
					this.showlist = true;
					this.showspinner = false;
				})
			}
			if (event.data.res && event.data.res === 'keywordSales') {
				this.showlist = false;
				console.log(event.data)
				if (this.showCoupangOneStarRate) {
					if (!this.headers.find(f => f.value == '쿠팡1점리뷰비율')) {
						this.headers.splice(-1, 0, { text: '쿠팡\n1점리뷰비율', value: '쿠팡1점리뷰비율', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangMedianSales) {
					if (!this.headers.find(f => f.value == '쿠팡중간매출')) {
						this.headers.splice(-1, 0, { text: '쿠팡\n중간매출', value: '쿠팡중간매출', align: 'center', width: 100, isSlot: true });
					}
				}
				if (this.showCoupangAvgSales) {
					if (!this.headers.find(f => f.value == '쿠팡평균매출')) {
						this.headers.splice(-1, 0, { text: '쿠팡\n평균매출', value: '쿠팡평균매출', align: 'center', width: 100, isSlot: true });
					}
				}

				var keywordSales = event.data.keywordSales;
				keyword = event.data.keyword;
				console.log(keywordSales)
				var worstReviewRate = Math.round(keywordSales.reduce((pv, cv) => { return pv + parseInt(cv.worstReviewQty) }, 0) / keywordSales.reduce((pv, cv) => { return pv + parseInt(cv.totalReviewQty) }, 0) * 10000) / 100 + "%"
				if (keywordSales) {
					if (this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", ""))) {
						array = keywordSales.map(f => f.monthSales).sort((a, b) => { return b - a });
						index50 = Math.floor(array.length * 0.50);
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).쿠팡중간매출 = array[index50];
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).쿠팡평균매출 = Math.round(array.reduce((pv, cv) => { return pv + cv }, 0) / array.length);
						this.keywords.find(f => f.keyword.replaceAll(" ", "") == keyword.replaceAll(" ", "")).쿠팡1점리뷰비율 = worstReviewRate;
					}
				}
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});
				this.$nextTick(() => {
					this.showlist = true;
					this.showspinner = false;
				})
			}

			if (event.data.res && event.data.res === 'relkeywordsrate') {
				this.loadingrate = '(' + event.data.n + '차)' + (event.data.rate * 100).toFixed(0) + '%';
			}
			if (event.data.res && event.data.res === 'relatedkeywords') {
				console.log(event.data.relatedkeywords)
				var arr = event.data.relatedkeywords;
				var ar = [];
				if (this.related) {
					ar = [this.targetKeyword]
				}
				// console.log(arr)
				arr.forEach(ele => {
					if (!ar.includes(ele)) {
						ar.push(ele)
					}
				})
				var keywords = [];
				// console.log(ar)
				ar.forEach(ele => {
					if (!this.rowlines.find(element => element.targetKeyword == ele)) {
						keywords.push(ele);
					}
				})
				// console.log(keywords)
				// this.rowlines = [];
				var cnt = 0
				for (var i = 0; i < keywords.length; i++) {
					cnt++
					this.rowlines.push({
						targetKeyword: keywords[i],
						wing1p: '',
						rocket1p: '',
						growth1p: '',
						adrate: '',
						adqty: '',
						adplace: '',
						cpc: '',
						cpchigh: '',
						cpclow: '',
						rocketrate: '',
						rocketqty: '',
						totalqty: '',
						crate: '',
						review1p: '',
						review1phigh: '',
						review1plow: '',
						review1pmiddle: '',
						n1psalesqty: '',
						n1psalesqtyhigh: '',
						n1psalesqtylow: '',
						n1psalesqtymiddle: '',
						n1psales: '',
						n1psaleshigh: '',
						n1psaleslow: '',
						n1psalesmiddle: '',

						nrate: '',
						pricen1phigh: '',
						pricen1plow: '',
						pricen1p: '',
						pricen1pmiddle: '',
						price1phigh: '',
						price1plow: '',
						price1p: '',
						price1pmiddle: '',
						wingrate: '',
					})

				}
				if (cnt == keywords.length) {
					this.importresults(keywords)
				}
			}
		}
		window.addEventListener('message', this.messageEventHandler);

		console.log(navigator)
		this.getauthNo()
	},
	computed: {
		slotHeaders() {
			return this.headers.filter(header => header.value !== 'add' && header.value !== 'del' && header.isSlot);
		}
	},
	methods: {
		updateHeaders() {
			// 기본 headers 설정
			let baseHeaders = [
				{ text: '키워드', value: 'keyword', align: 'center', width: 200, isSlot: false },
				{ text: '연관+자동완성', value: 'add', align: 'center', width: 100, isSlot: true },
				{ text: '삭제', value: 'del', align: 'center', width: 100, isSlot: true }
			];

			// 키워드 데이터에 따라 headers 확장
			if (this.keywords.length > 0) {
				let insertIndex = 1; // '키워드' 컬럼 다음에 삽입

				// 1. 검색트렌드 관련 컬럼
				if (this.keywords.some(k => k.검색트렌드지난달 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '검색트렌드\n지난달', value: '검색트렌드지난달', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k.검색트렌드이번달 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '검색트렌드\n이번달', value: '검색트렌드이번달', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k['검색트렌드+3개월'] !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '검색트렌드\n+3개월', value: '검색트렌드+3개월', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k['검색트렌드+6개월'] !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '검색트렌드\n+6개월', value: '검색트렌드+6개월', align: 'center', width: 100, isSlot: true });
				}

				// 2. CPC 관련 컬럼
				if (this.keywords.some(k => k.쿠팡키워드CPC !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '쿠팡\n키워드CPC', value: '쿠팡키워드CPC', align: 'center', width: 100, isSlot: true });
				}

				// 3. 쿠팡 가격 관련 컬럼
				if (this.keywords.some(k => k.쿠팡중간판매가 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '쿠팡\n중간판매가', value: '쿠팡중간판매가', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k.쿠팡판매가범위 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '쿠팡판매가\n범위(25~75%)', value: '쿠팡판매가범위', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k.쿠팡평균판매가 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '쿠팡\n평균판매가', value: '쿠팡평균판매가', align: 'center', width: 100, isSlot: true });
				}

				// 4. 상위 비율 관련 컬럼
				if (this.keywords.some(k => k.상위그로스비율 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '상위\n그로스비율', value: '상위그로스비율', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k.상위로켓비율 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '상위\n로켓비율', value: '상위로켓비율', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k.상위윙비율 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '상위\n윙비율', value: '상위윙비율', align: 'center', width: 100, isSlot: true });
				}

				// 5. 카테고리 관련 컬럼
				if (this.keywords.some(k => k.카테고리명 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '카테고리명', value: '카테고리명', align: 'center', width: 200, isSlot: true },
						{ text: '카테고리\n적합도', value: '카테고리적합도', align: 'center', width: 100, isSlot: true });
					insertIndex++; // 카테고리명과 적합도 2개 컬럼이 추가되어서 인덱스 1 더 증가
				}

				// 6. 조회수, 매출 관련 컬럼
				if (this.keywords.some(k => k.평균월조회수 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '상위\n평균월조회수', value: '평균월조회수', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k.상위예상월매출 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '상위\n예상월매출\n(CVR3%\n중간판매가\n기준)', value: '상위예상월매출', align: 'center', width: 100, isSlot: true });
				}
				if (this.keywords.some(k => k.상위예상일판매 !== undefined)) {
					baseHeaders.splice(insertIndex++, 0,
						{ text: '상위\n예상일판매\n(CVR3%\n기준)', value: '상위예상일판매', align: 'center', width: 100, isSlot: true });
				}
			}

			this.headers = baseHeaders;
		},
		async clearKeywords() {
			try {
				await this.db.clearKeywords();
				this.keywords = [];
				this.showlist = false;
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});
			} catch (error) {
				console.error('키워드 삭제 실패:', error);
			}
		},
		async deleteKeywords() {
			if (this.tableSelectedItem.length > 0) {
				if (confirm(this.tableSelectedItem.length + '개의 키워드를 삭제하시겠습니까?')) {

					// ✔ 선택된 항목을 복제해둔다
					const selectedItems = [...this.tableSelectedItem];

					// 복제본을 순회하면서 삭제
					for (const item of selectedItems) {
						this.deleteKeyword(item);
					}

					// 다 끝난 후에 한 번에 비우기
					this.tableSelectedItem = [];
				}
			} else {
				if (confirm('전체 키워드를 삭제하시겠습니까?')) {
					await this.clearKeywords();
					this.headers = [
						{ text: '키워드', value: 'keyword', align: 'center', width: 200, isSlot: false },
						{ text: '연관+자동완성', value: 'add', align: 'center', width: 100, isSlot: true },
						{ text: '삭제', value: 'del', align: 'center', width: 100, isSlot: true },
					];
				}
			}
		}
		,
		keyinkeywords() {
			if (this.targetKeyword.length > 10 && this.targetKeyword.split(' ').length > 2) {
				console.log(this.targetKeyword.split(' '))
			}
		},
		predictFutureSearchVolumes(monthlySearch, oneYearData) {
			// 월별 검색량 문자열을 숫자로 변환(콤마 제거)
			monthlySearch = parseInt(monthlySearch) > 0 ? Number(String(monthlySearch).replace(/,/g, '')) : 0;
			console.log(monthlySearch, oneYearData);
			const today = new Date();

			// 날짜를 YYYY-MM-DD로 포맷하는 헬퍼 함수
			function formatDate(date) {
				const y = date.getFullYear();
				const m = String(date.getMonth() + 1).padStart(2, '0');
				const d = String(date.getDate()).padStart(2, '0');
				return `${y}-${m}-${d}`;
			}

			// 특정 기간(시작일~종료일) 내 평균 ratio 계산 함수
			// 날짜가 oneYearData에 없으면 ratio=0으로 계산
			function getAverageRatio(startDate, endDate) {
				const startStr = formatDate(startDate);
				const endStr = formatDate(endDate);

				// 기간 내 총 일수 계산
				const dayCount = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

				let totalRatio = 0;
				let count = 0;

				for (let i = 0; i < dayCount; i++) {
					const currentDate = new Date(startDate.getTime());
					currentDate.setDate(currentDate.getDate() + i);

					const currentStr = formatDate(currentDate);
					if (currentStr < startStr || currentStr > endStr) continue; // 범위 외는 제외

					// oneYearData에서 해당 날짜를 찾는다
					const dataItem = oneYearData.find(item => item.period === currentStr);

					// 없으면 ratio=0 처리
					const ratioValue = dataItem ? dataItem.ratio : 0;
					totalRatio += ratioValue;
					count++;
				}

				if (count === 0) return 0;
				return totalRatio / count;
			}

			// 직전 30일 평균 ratio 계산
			const lastMonthEnd = new Date(today);
			const lastMonthStart = new Date(today);
			lastMonthStart.setDate(lastMonthStart.getDate() - 30);
			const lastMonthAvgRatio = getAverageRatio(lastMonthStart, lastMonthEnd);

			// ratio 1 단위당 실제 검색량 변환
			const ratioToVolumeFactor = lastMonthAvgRatio === 0 ? 0 : (monthlySearch / lastMonthAvgRatio);

			// 미래 기간 설정(1년 전 동일 기간 사용)
			const oneMonthLaterStart = new Date(today);
			oneMonthLaterStart.setMonth(oneMonthLaterStart.getMonth() + 1);
			const oneMonthLaterEnd = new Date(oneMonthLaterStart);
			oneMonthLaterEnd.setDate(oneMonthLaterEnd.getDate() + 30);

			const oneMonthLaterStartLastYear = new Date(oneMonthLaterStart);
			oneMonthLaterStartLastYear.setFullYear(oneMonthLaterStartLastYear.getFullYear() - 1);
			const oneMonthLaterEndLastYear = new Date(oneMonthLaterEnd);
			oneMonthLaterEndLastYear.setFullYear(oneMonthLaterEndLastYear.getFullYear() - 1);

			// 3개월 후 예측
			const threeMonthsLaterStart = new Date(today);
			threeMonthsLaterStart.setMonth(threeMonthsLaterStart.getMonth() + 3);
			const threeMonthsLaterEnd = new Date(threeMonthsLaterStart);
			threeMonthsLaterEnd.setDate(threeMonthsLaterEnd.getDate() + 30);

			const threeMonthsLaterStartLastYear = new Date(threeMonthsLaterStart);
			threeMonthsLaterStartLastYear.setFullYear(threeMonthsLaterStartLastYear.getFullYear() - 1);
			const threeMonthsLaterEndLastYear = new Date(threeMonthsLaterEnd);
			threeMonthsLaterEndLastYear.setFullYear(threeMonthsLaterEndLastYear.getFullYear() - 1);

			// 6개월 후 예측
			const sixMonthsLaterStart = new Date(today);
			sixMonthsLaterStart.setMonth(sixMonthsLaterStart.getMonth() + 6);
			const sixMonthsLaterEnd = new Date(sixMonthsLaterStart);
			sixMonthsLaterEnd.setDate(sixMonthsLaterEnd.getDate() + 30);

			const sixMonthsLaterStartLastYear = new Date(sixMonthsLaterStart);
			sixMonthsLaterStartLastYear.setFullYear(sixMonthsLaterStartLastYear.getFullYear() - 1);
			const sixMonthsLaterEndLastYear = new Date(sixMonthsLaterEnd);
			sixMonthsLaterEndLastYear.setFullYear(sixMonthsLaterEndLastYear.getFullYear() - 1);

			// 과거 1년 전 동일 기간의 평균 ratio 계산
			const avgRatioOneMonthLater = getAverageRatio(oneMonthLaterStartLastYear, oneMonthLaterEndLastYear);
			const avgRatioThreeMonthsLater = getAverageRatio(threeMonthsLaterStartLastYear, threeMonthsLaterEndLastYear);
			const avgRatioSixMonthsLater = getAverageRatio(sixMonthsLaterStartLastYear, sixMonthsLaterEndLastYear);

			// 예측 검색량 계산
			const oneMonthLaterVolume = ratioToVolumeFactor * avgRatioOneMonthLater;
			const threeMonthsLaterVolume = ratioToVolumeFactor * avgRatioThreeMonthsLater;
			const sixMonthsLaterVolume = ratioToVolumeFactor * avgRatioSixMonthsLater;

			return {
				oneMonthLaterVolume,
				threeMonthsLaterVolume,
				sixMonthsLaterVolume
			};
		},
		checkShowItems() {
			if (this.check1) {
				this.showCoupangMonthlySearch = true;
				this.showCoupangRecCPC = true;
				this.showSearchTrend = true;
			} else {
				this.showCoupangMonthlySearch = false;
				this.showCoupangRecCPC = false;
				this.showSearchTrend = false;
			}
			if (this.check2) {
				this.showCoupangMedianPrice = true;
				this.showCoupangPriceRange = true;
				this.showCoupangAvgPrice = true;
				this.showCoupangTopGrowthRate = true;
				this.showCoupangTopRocketRate = true;
				this.showCoupangTopWingRate = true;
				this.showOptimalCategory = true;
				this.showCoupangAvgViews = true;
			} else {
				this.showCoupangMedianPrice = false;
				this.showCoupangPriceRange = false;
				this.showCoupangAvgPrice = false;
				this.showCoupangTopGrowthRate = false;
				this.showCoupangTopRocketRate = false;
				this.showCoupangTopWingRate = false;
				this.showOptimalCategory = false;
				this.showCoupangAvgViews = false;
			}
			if (this.check3) {
				this.showCoupangOneStarRate = true;
				this.showCoupangMedianSales = true;
				this.showCoupangAvgSales = true;
				// this.showCoupangMedianViews = true;

			} else {
				this.showCoupangOneStarRate = false;
				this.showCoupangMedianSales = false;
				this.showCoupangAvgSales = false;
				// this.showCoupangMedianViews = false;

			}
		},
		downloadKeywords() {
			let downloadItems = [];

			// 다운로드할 아이템 선택
			if (this.tableSelectedItem.length > 0) {
				if (confirm(this.tableSelectedItem.length + '개의 키워드를 다운로드 하시겠습니까?')) {
					downloadItems = this.tableSelectedItem;
				}
			} else {
				if (confirm('전체 키워드를 다운로드 하시겠습니까?')) {
					downloadItems = this.keywords;
				}
			}

			if (downloadItems.length > 0) {
				// 데이터 정리 및 헤더와 키값 매칭
				const processedData = downloadItems.map(item => {
					let newItem = {
						'키워드': item.keyword,
						'검색트렌드_지난달': item.검색트렌드지난달,
						'검색트렌드_이번달': item.검색트렌드이번달,
						'검색트렌드_3개월': item['검색트렌드+3개월'],
						'검색트렌드_6개월': item['검색트렌드+6개월'],
						'쿠팡키워드CPC': item.쿠팡키워드CPC,
						'쿠팡중간판매가': item.쿠팡중간판매가,
						'쿠팡판매가범위': item.쿠팡판매가범위,
						'쿠팡평균판매가': item.쿠팡평균판매가,
						'상위그로스비율': item.상위그로스비율,
						'상위로켓비율': item.상위로켓비율,
						'상위윙비율': item.상위윙비율,
						'카테고리명': item.카테고리명,
						'카테고리적합도': item.카테고리적합도,
						'평균월조회수': item.평균월조회수,
						'상위예상월매출': item.상위예상월매출,
						'상위예상일판매': item.상위예상일판매,
						'쿠팡1점리뷰비율': item.쿠팡1점리뷰비율,
						'쿠팡중간매출': item.쿠팡중간매출,
						'쿠팡평균매출': item.쿠팡평균매출
					};

					// undefined 값을 빈 문자열로 변환
					Object.keys(newItem).forEach(key => {
						if (newItem[key] === undefined) {
							newItem[key] = '';
						}
					});

					return newItem;
				});

				const date = new Date();
				const today = date.getFullYear() +
					("0" + (1 + date.getMonth())).slice(-2) +
					("0" + date.getDate()).slice(-2);

				const workBook = XLSX.utils.book_new();
				const workSheet = XLSX.utils.json_to_sheet(processedData, {
					header: Object.keys(processedData[0])
				});

				XLSX.utils.book_append_sheet(workBook, workSheet, '키워드분석');
				XLSX.writeFile(workBook, '키워드분석_' + today + '.xlsx');
			}
		},
		deleteKeyword(item) {
			var i = this.keywords.findIndex(e => e.keyword == item.keyword);
			if (i !== -1) {
				// 키워드 배열에서 제거
				this.keywords = this.keywords.slice(0, i).concat(this.keywords.slice(i + 1));

				// 혹시 선택목록에 해당 item이 남아 있으면 제거
				const selIndex = this.tableSelectedItem.indexOf(item);
				if (selIndex !== -1) {
					this.tableSelectedItem.splice(selIndex, 1);
				}

				// DB에 즉시 저장 (삭제 후)
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});
			}

			if (this.keywords.length == 0) {
				this.headers = [
					{ text: '키워드', value: 'keyword', align: 'center', width: 200, isSlot: false },
					{ text: '연관+자동완성', value: 'add', align: 'center', width: 100, isSlot: true },
					{ text: '삭제', value: 'del', align: 'center', width: 100, isSlot: true },
				];
			}
		},
		collectRelKeyword() {
			if (!this.targetKeyword) {
				alert('수집할 검색키워드를 입력해 주세요')
				return
			}
			if (!this.relKeywordChecked && !this.autoCompleteChecked) {
				alert('연관 또는 자동완성 키워드를 체크해 주세요')
				return
			}
			window.postMessage({ greeting: "getadkeyword", data: [this.targetKeyword], cnt: this.relCycle, relKeywordChecked: this.relKeywordChecked, autoCompleteChecked: this.autoCompleteChecked }, "*");
			this.loadingtext = '연관/자동완성 키워드 수집 중';
			this.loadingrate = '';
			this.showspinner = true;
			// this.showlist = false;
		},
		addRelKeyword(item) {
			window.postMessage({ greeting: "getadkeyword", data: [item.keyword], cnt: 1, relKeywordChecked: true, autoCompleteChecked: true }, "*");
			item.add = true;
			this.loadingtext = '연관/자동완성 키워드 수집 중';
			this.loadingrate = '';
			this.showspinner = true;
			// this.showlist = false;
		},
		async uploadfile(event) {
			try {
				this.showuploadspinner = true;

				const file = event.target.files[0];
				const data = await this.readFileAsync(file);
				const workbook = XLSX.read(data, { type: 'binary' });

				for (const sheetName of workbook.SheetNames) {
					const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
					console.log(roa);

					for (var e of roa) {
						if (e.키워드.length > 0) {
							var keyword = e.키워드
							if (!this.keywords.find(f => f.keyword == keyword)) {
								this.keywords.push({
									keyword: keyword,
									add: false,
									del: false,
								})
							}
						}
					}
				}

				this.$nextTick(() => {
					this.showlist = true;
					this.showspinner = false;
					this.showuploadspinner = false;
					this.$bvModal.hide('excelupload');
				});

				// 엑셀 업로드 끝난 뒤 DB 저장
				this.db.saveKeywords(this.keywords).catch(error => {
					console.error('키워드 저장 실패:', error);
				});

			} catch (error) {
				alert(error.message);
				this.showuploadspinner = false;
				this.$bvModal.hide('excelupload');
			}
		},
		async readFileAsync(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = (e) => resolve(e.target.result);
				reader.onerror = (error) => reject(error);
				reader.readAsBinaryString(file);
			});
		},
		formatDownload() {
			var arr = [];
			var myHeader = ['키워드'];

			var dataWS = XLSX.utils.json_to_sheet(arr, { header: myHeader });

			const range = XLSX.utils.decode_range(dataWS['!ref']);
			range.e['c'] = myHeader.length - 1;
			dataWS['!ref'] = XLSX.utils.encode_range(range);

			var wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, dataWS, '키워드업로드양식');
			// 엑셀 파일을 내보낸다.
			XLSX.writeFile(wb, '키워드업로드양식.xlsx');
		},
		async collectKeyword() {
			window.postMessage({ greeting: "collectKeyword", startRank: this.startRank, endRank: this.endRank }, "*",)
		},
		async getauthNo() {
			axios.post('/api/getAuthNo')
				.then(res => {
					this.auth = res.data.auth;
				}).catch(console.log)
		},
		resetitems() {
			this.rowlines = [];
		},
		searchenter(event) {
			if (event.keyCode === 13) {
				this.search();
			}
		},
		addKeyword(event) {
			if (event.keyCode === 13) {
				if (!this.targetKeyword) {
					return
				}
				if (this.targetKeyword.length > 10 && this.targetKeyword.split(' ').length > 3) {
					for (var keyword of this.targetKeyword.split(' ')) {
						if (keyword.length > 0) {
							if (!this.keywords.find(e => e.keyword.replaceAll(" ", "") == keyword)) {
								this.keywords.push({
									keyword: keyword,
									add: false,
									del: false,
								})
							}
						}
					}
					this.targetKeyword = "";
					this.showlist = true;
					return
				}
				if (!this.keywords.find(e => e.keyword.replaceAll(" ", "") == this.targetKeyword.replaceAll(" ", ""))) {
					if (this.targetKeyword.replaceAll(" ", "").length > 0) {
						this.keywords.push({
							keyword: this.targetKeyword.replaceAll(" ", ""),
							add: false,
							del: false,
						})
					}
					this.targetKeyword = "";
					this.showlist = true;
					return
				}
				this.db.saveKeywords(this.keywords).catch(error => { console.error('키워드 저장 실패:', error); });
			}
		},
		collectRelKeywordEnter(event) {
			if (event.keyCode === 13) {
				this.$bvModal.hide('relKeyword');
				this.collectRelKeyword();
			}
		},
		startfocus() {
			this.$refs.cursor.focus();
		},
		middlelevel(arr) {
			arr.sort((a, b) => a - b);
			var val = parseInt(arr.length / 2);
			return arr[val];
		},
		async makerowlines(coupang, naver, cpc) {
			var cpcobj = cpc;
			console.log(coupang)
			console.log(naver)
			console.log(cpc)
			this.rowlines.forEach(e => {
				// console.log(coupang)
				// console.log(e)
				if (coupang.find(ele => ele.keyword == e.targetKeyword)) {
					var coupang1p = coupang.find(ele => ele.keyword == e.targetKeyword);
					var coupang1plist = coupang1p.productlists1p.filter(ele => ele.ad == false);
					// console.log(coupang1plist.map(ele => ele.ratingcount))
					var naver1p = naver.find(ele => ele.keyword == e.targetKeyword).keywordvalue;
					var naverlist = naver.find(ele => ele.keyword == e.targetKeyword).products;
					// console.log(naverlist.filter(ele => ele.purchaseCnt >= 0))
					for (var j = 0; j < naver1p.length; j++) {
						naver1p[j].long = parseInt(naver1p[j].long > naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt / 26 ? naver1p[j].long : naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt / 26);

					}

					var cpc = cpcobj ? cpcobj[e.targetKeyword] : '';

					e.wing1p = coupang1plist.filter(ele => ele.type == 'wing').length;
					e.rocket1p = coupang1plist.filter(ele => ele.type == 'rocket').length;
					e.growth1p = coupang1plist.filter(ele => ele.type == 'jet').length;
					e.wingrate = (e.wing1p / (e.wing1p + e.rocket1p + e.growth1p) * 100).toFixed(2);
					e.adqty = coupang1p.adqty;
					e.adplace = coupang1p.adplace;
					e.adrate = (coupang1p.adqty / coupang1p.adplace * 100).toFixed(2);
					e.cpchigh = cpc ? cpc.high : 0;
					e.cpclow = cpc ? cpc.low : 0;
					e.cpc = cpc ? cpc.suggested : 0;
					e.rocketqty = coupang1p.rocketqty;
					e.totalqty = coupang1p.totalqty;
					e.rocketrate = (coupang1p.rocketqty / coupang1p.totalqty * 100).toFixed(2);

					e.review1phigh = coupang1plist.reduce((pv, cv) => { return pv > cv.ratingcount ? pv : cv.ratingcount }, 0);
					e.review1plow = coupang1plist.reduce((pv, cv) => { return pv < cv.ratingcount ? pv : cv.ratingcount }, 100000000);
					e.review1p = Math.round(coupang1plist.reduce((pv, cv) => { return pv + cv.ratingcount }, 0) / coupang1plist.length);
					e.review1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.ratingcount));

					e.price1phigh = coupang1plist.reduce((pv, cv) => { return pv > cv.price ? pv : cv.price }, 0);
					e.price1plow = coupang1plist.reduce((pv, cv) => { return pv < cv.price ? pv : cv.price }, 100000000);
					e.price1p = Math.round(coupang1plist.reduce((pv, cv) => { return pv + cv.price }, 0) / coupang1plist.length);
					e.price1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.price));

					e.pricen1phigh = naver1p.reduce((pv, cv) => { return pv > cv.price ? pv : cv.price }, 0);
					e.pricen1plow = naver1p.reduce((pv, cv) => { return pv < cv.price ? pv : cv.price }, 100000000);
					e.pricen1p = Math.round((naver1p.reduce((pv, cv) => { return pv + cv.price }, 0)) / naver1p.length);
					e.pricen1pmiddle = this.middlelevel(naver1p.map(ele => ele.price));
					// e.crate = (e.review1p/e.totalqty).toFixed(2);
					e.n1psalesqtyhigh = naver1p.reduce((pv, cv) => { return pv > cv.long ? pv : cv.long }, 0);
					e.n1psalesqtylow = naver1p.reduce((pv, cv) => { return pv < cv.long ? pv : cv.long }, 100000000);
					e.n1psalesqty = Math.round((naver1p.reduce((pv, cv) => { return pv + cv.long }, 0)) / naver1p.length);
					e.n1psalesqtymiddle = this.middlelevel(naver1p.map(ele => ele.long));

					e.n1psaleshigh = naver1p.reduce((pv, cv) => { return pv > cv.long * cv.price ? pv : cv.long * cv.price }, 0);
					e.n1psaleslow = naver1p.reduce((pv, cv) => { return pv < cv.long * cv.price ? pv : cv.long * cv.price }, 100000000);
					e.n1psales = Math.round((naver1p.reduce((pv, cv) => { return pv + cv.long * cv.price }, 0)) / naver1p.length);
					e.n1psalesmiddle = this.middlelevel(naver1p.map(ele => ele.long * ele.price));

				}
			})
			setTimeout(() => {
				this.loadingtext = '키워드 별 CPC단가 분석 중';
				this.loadingrate = '';
				this.showspinner = false;
				this.showlist = true;
			}, 1000)


		},
		async sleep(ms) {
			return new Promise((r) => setTimeout(r, ms));
		},
		async importresults(arr) {
			await this.sleep(1000);
			this.loadingtext = '키워드 별 CPC단가 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			// this.showlist = false;
			window.postMessage({ greeting: "keywordanalysis", data: arr, auth: this.auth }, "*",)
			// // this.waitresult = false;
			// for(var i=0;i<this.rowlines.length;i++){
			// 	var e = this.rowlines[i];
			// 	await this.sleep(1000)
			// 	if(e.targetKeyword || e.productName || e.itemId || e.productId || e.vendorItemId){
			// 		e.showspinner = true;
			// 		e.rank = [];
			// 		e.adqty = '';
			// 		e.adplace = '';
			// 		e.productqty = '';
			// 		e.rocketqty = '';
			// 		e.totalrank = [];
			// 		var data = {
			// 			targetKeyword : e.targetKeyword,
			// 			productName : e.productName,
			// 			itemId : e.itemId,
			// 			productId : e.productId,
			// 			vendorItemId : e.vendorItemId,
			// 			index : i,
			// 			fullsearch : false,
			// 		}
			// 		// console.log(data)
			// 		window.postMessage({greeting: "coupangrank",data:data},"*",)
			// 		// this.waitresult = true;
			// 	}
			// }
			// // this.waitresult = false;
		},
		changelines() {
			this.rowlines = [];
			for (var i = 0; i < this.addlines; i++) {
				this.rowlines.push({ targetKeyword: '', productName: '', itemId: '', productId: '', vendorItemId: '', rank: [], adqty: '', adplace: '', productqty: '', rocketqty: '', showspinner: false, totalrank: [] })
			}
		},
		resetitem(i) {
			this.rowlines[i].targetKeyword = '';
			this.rowlines[i].productName = '';
			this.rowlines[i].itemId = '';
			this.rowlines[i].productId = '';
			this.rowlines[i].rank = [];
			this.rowlines[i].adqty = '';
			this.rowlines[i].adplace = '';
			this.rowlines[i].productqty = '';
			this.rowlines[i].rocketqty = '';
			this.rowlines[i].showspinner = false;
			this.rowlines[i].totalrank = [];
		},
		deleteitem(i) {
			this.rowlines = this.rowlines.slice(0, i).concat(this.rowlines.slice(i + 1))
			if (this.rowlines.length == 0) {
				this.showlist = false;
			}
			this.addlines--
		},
		search() {
			var collectItems = [];
			if (this.tableSelectedItem.length > 0) {
				if (confirm(this.tableSelectedItem.length + '개의 키워드 데이터를 수집 하시겠습니까?')) {
					collectItems = this.tableSelectedItem
				}
			} else {
				if (confirm('전체 키워드 데이터를 수집 하시겠습니까?')) {
					collectItems = this.keywords.filter(e => Object.keys(e).length <= 3);
				}
			}
			if (collectItems.length == 0) {
				alert('수집할 키워드가 없습니다.')
				return
			}
			if (!this.showCoupangMonthlySearch &&
				!this.showCoupangMedianPrice &&
				!this.showCoupangPriceRange &&
				!this.showCoupangOneStarRate &&
				!this.showOptimalCategory &&
				!this.showCoupangAvgPrice &&
				!this.showCoupangMedianSales &&
				!this.showCoupangAvgSales &&
				// !this.showCoupangMedianViews &&
				!this.showCoupangAvgViews &&
				!this.showCoupangRecCPC &&
				!this.showCoupangTopGrowthRate &&
				!this.showCoupangTopRocketRate &&
				!this.showCoupangTopWingRate &&
				// !this.showNaverAvgSales &&
				!this.showSearchTrend
			) {
				alert('수집할 데이터를 선택해주세요')
				return
			}
			window.postMessage({
				greeting: "getCollectKeywordData",
				keywords: collectItems,
				datalabAPI: {
					clientId: this.$store.state.settings[0].clientId ? this.$store.state.settings[0].clientId : '',
					clientSecret: this.$store.state.settings[0].clientSecret ? this.$store.state.settings[0].clientSecret : '',
				},
				data: {
					showCoupangMonthlySearch: this.showCoupangMonthlySearch,
					showCoupangMedianPrice: this.showCoupangMedianPrice,
					showCoupangPriceRange: this.showCoupangPriceRange,
					showCoupangOneStarRate: this.showCoupangOneStarRate,
					showOptimalCategory: this.showOptimalCategory,
					showCoupangAvgPrice: this.showCoupangAvgPrice,
					showCoupangMedianSales: this.showCoupangMedianSales,
					showCoupangAvgSales: this.showCoupangAvgSales,
					// showCoupangMedianViews: this.showCoupangMedianViews,
					showCoupangAvgViews: this.showCoupangAvgViews,
					showCoupangRecCPC: this.showCoupangRecCPC,
					showCoupangTopGrowthRate: this.showCoupangTopGrowthRate,
					showCoupangTopRocketRate: this.showCoupangTopRocketRate,
					showCoupangTopWingRate: this.showCoupangTopWingRate,
					// showNaverAvgSales: this.showNaverAvgSales,
					showSearchTrend: this.showSearchTrend,
				}
			}, "*",)

			this.loadingtext = '키워드 데이터 수집 중';
			this.loadingrate = '';
			this.showspinner = true;
			// this.showlist = false;
		},
		searchresult() {
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if (this.$store.state.sales.length > 0) {
				this.$store.state.sales.sort(function (a, b) {
					if (a.date < b.date) {
						return -1;
					}
					if (a.date > b.date) {
						return 1;
					}
					return 0;
				})
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				this.$store.state.productdb.forEach(e => {
					if (this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length) {
						obj = {
							썸네일: e.썸네일,
							판매상품명: e.판매상품명,
							SKUID: e["SKU ID"],
							바코드: e.바코드,
							발주가능상태: e.발주가능상태,
							재고: 0,
							판매: 0,
							입고: 0,
							판매가: 0,
							판매매출: 0,
							공급매출: 0,
							리뷰수: 0,
							리뷰평점: 0,
							판매링크: e.판매링크,
							판매세부내역: []
						}
						if (this.$store.state.sales.length > 1) {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if (this.$store.state.supply.find(element => element.importdate == ele.date)) {
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if (su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0) {
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv, rv) => { return pv + parseInt(rv.수량) }, 0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if (이전재고 == '') {
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date: ele.date,
									발주가능상태: e.발주가능상태,
									재고: t.s + 입고수량 ? t.s + 입고수량 : 0,
									판매: 0,
									입고: 입고수량 ? 입고수량 : 0,
									판매가: t.p ? t.p : 0,
									판매매출: 0,
									공급매출: 0,
									리뷰수: t.rq ? t.rq : 0,
									리뷰평점: t.rp ? t.rp : 0,
									판매링크: e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							for (var j = 1; j < obj.판매세부내역.length; j++) {
								salesqty = obj.판매세부내역[j - 1].재고 - obj.판매세부내역[j].재고 + obj.판매세부내역[j].입고 ? obj.판매세부내역[j - 1].재고 - obj.판매세부내역[j].재고 + obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty * obj.판매세부내역[j].판매가 ? salesqty * obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty * obj.판매세부내역[j].판매가 ? salesqty * obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty * e.매입가 ? salesqty * e.매입가 : 0;
								obj.공급매출 += salesqty * e.매입가 ? salesqty * e.매입가 : 0;
							}
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if (this.$store.state.supply.find(element => element.importdate == ele.date)) {
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if (su.importlist.find(element => element.sku번호.toString() == t.sk.toString())) {
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date: ele.date,
									발주가능상태: e.발주가능상태,
									재고: t.s ? t.s : 0,
									판매: 0,
									입고: 입고수량 ? 입고수량 : 0,
									판매가: t.p ? t.p : 0,
									판매매출: 0,
									공급매출: 0,
									리뷰수: t.rq ? t.rq : 0,
									리뷰평점: t.rp ? t.rp : 0,
									판매링크: e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => { 이전재고 = d.재고 ? d.재고 : 0 }, 1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})

				this.salescnt = this.saleslists.reduce((pv, rv) => { return pv + rv.판매 }, 0)
				this.importcnt = this.saleslists.reduce((pv, rv) => { return pv + rv.입고 }, 0)
				this.salesamount = this.saleslists.reduce((pv, rv) => { return pv + rv.판매매출 }, 0)
				this.supplyamount = this.saleslists.reduce((pv, rv) => { return pv + rv.공급매출 }, 0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if (this.saleslists.length == 0) {
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}

		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date) {
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
		timestamp(date) {
			//inputdate = "yyyy-mm-dd"
			return new Date(date).getTime()
		},
		getTodaywith() {
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		download() {
			function getToday() {
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			this.rowlines.forEach(e => {
				arr.push({
					키워드: e.targetKeyword,
					쿠팡1p윙비율: e.wingrate,
					쿠팡1p윙제품수: e.wing1p,
					쿠팡1p로켓제품수: e.rocket1p,
					쿠팡1p그로스제품수: e.growth1p,
					쿠팡광고비율: e.adrate,
					쿠팡광고상품수: e.adqty,
					쿠팡광고구좌수: e.adplace,
					쿠팡광고CPC단가_추천: e.cpc,
					쿠팡광고CPC단가_최대: e.cpchigh,
					쿠팡광고CPC단가_최저: e.cpclow,
					쿠팡로켓비율: e.rocketrate,
					쿠팡로켓상품수: e.rocketqty,
					쿠팡전체상품수: e.totalqty,

					쿠팡1p리뷰수_평균: e.review1p,
					쿠팡1p리뷰수_최대: e.review1phigh,
					쿠팡1p리뷰수_최저: e.review1plow,
					쿠팡1p리뷰수_중간: e.review1pmiddle,

					쿠팡1p판매가_평균: e.price1p,
					쿠팡1p판매가_최대: e.price1phigh,
					쿠팡1p판매가_최저: e.price1plow,
					쿠팡1p판매가_중간: e.price1pmiddle,

					네이버1p판매가_평균: e.pricen1p,
					네이버1p판매가_최대: e.pricen1phigh,
					네이버1p판매가_최저: e.pricen1plow,
					네이버1p판매가_중간: e.pricen1pmiddle,

					네이버1p월판매수량_평균: e.n1psalesqty,
					네이버1p월판매수량_최대: e.n1psalesqtyhigh,
					네이버1p월판매수량_최저: e.n1psalesqtylow,
					네이버1p월판매수량_중간: e.n1psalesqtymiddle,
					네이버1p월매출_평균: e.n1psales,
					네이버1p월매출_최대: e.n1psaleshigh,
					네이버1p월매출_최저: e.n1psaleslow,
					네이버1p월매출_중간: e.n1psalesmiddle,
				})
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '키워드분석')
			XLSX.writeFile(workBook, '키워드분석' + '_' + getToday() + '.xlsx')
		},
		settinglist() {

		}
	}

}
</script>

<style scoped></style>