// src/utils/indexedDB.js

export default class IndexedDBStore {
  constructor(dbName = 'couplustDB', storeName = 'keywords', version = 1) {
    this.dbName = dbName;
    this.storeName = storeName;
    this.version = version;
  }

  // DB 연결 및 초기화
  async initDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.version);

      request.onerror = () => {
        reject(new Error('DB 연결에 실패했습니다.'));
      };

      request.onsuccess = (event) => {
        this.db = event.target.result;
        resolve(this.db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName, { keyPath: 'id', autoIncrement: true });
        }
      };
    });
  }

  // 키워드 데이터 저장
  async saveKeywords(keywords) {
    if (!this.db) await this.initDB();
    
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      // 기존 데이터 삭제
      store.clear();

      // 새 데이터 저장
      const request = store.add({ id: 1, keywords: keywords });

      request.onsuccess = () => resolve();
      request.onerror = () => reject(new Error('키워드 저장에 실패했습니다.'));
    });
  }

  // 키워드 데이터 불러오기
  async getKeywords() {
    if (!this.db) await this.initDB();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.get(1);

      request.onsuccess = () => {
        resolve(request.result ? request.result.keywords : []);
      };
      request.onerror = () => reject(new Error('키워드 불러오기에 실패했습니다.'));
    });
  }

  // 데이터 삭제
  async clearKeywords() {
    if (!this.db) await this.initDB();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.clear();

      request.onsuccess = () => resolve();
      request.onerror = () => reject(new Error('키워드 삭제에 실패했습니다.'));
    });
  }
}